@import '../../ressources/styles/colors.scss';

#order-navigator {
    display: flex;
    justify-content: flex-end;

    button {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        background-color: $ost-blackberry;
        border: 2px solid transparent;
        border-radius: 5px;
        transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease;
    }

    button:hover {
        background-color: $ost-raspberry;
        cursor: pointer;
        color: #fff;
    }

    button:disabled {
        background-color: $ost-gray;
        color: $ost-black;
        cursor: default;
    }

    .order-amount-picker {
      margin-right: 40px;
    
      button {
        margin: 0;
        &:first-of-type {
          border-radius: 5px 0 0 5px;
        }
        &:last-of-type {
          border-radius: 0 5px 5px 0;
        }
      }
    
      input {
        height: 100%;
        width: 100px;
        text-align: center;

        // Remove Up/Down Arrows on Number Input
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type=number] {
          -moz-appearance: textfield;
        }
      }
    }
}

@media screen and(max-width: 900px) {
    #order-navigator {
        button {
            width: 100%;
        }
        h3 {
            margin-top: 16px;
            width: 100%;
            text-align: center;
        }
    }
}
