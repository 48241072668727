@import '../../ressources/styles/colors.scss';

.floorball-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;

    & > .floorball {
        flex-basis: 100%;
        text-align: center;
        max-width: 100%;

        .floorball {
            text-align: center;
            max-width: 300px;
            margin: 32px auto 0;
        }

        img {
            max-width: 300px;
        }
    }

    .data {
        text-align: left;
        flex-basis: calc(50%);
        h3,
        h4 {
            text-align: center;
        }
        .ui.table {
            width: 100%;
        }
        .ui.table tr td:first-child {
            width: 200px;
        }
    }
}

.component {
    .overview {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        column-gap: 24px;
        margin-bottom: 24px;

        .image {
            width: 150px;
            img {
                width: 100%;
            }
        }

        .ui.table {
            width: auto;
        }
    }

    .data {
        display: flex;
        flex-wrap: wrap;
        column-gap: 24px;
        justify-content: center;

        .topic {
            flex-basis: calc(50% - 12px);
            margin-bottom: 24px;
        }

        .ui.table {
            max-width: 100%;
            tr td:first-child {
                min-width: 200px;
            }
        }
    }
}

/* Responsive */

@media screen and (max-width: 767px) {
    .data {
        .ui.table {
            tr {
                padding: 0;
                td {
                    padding: 12px 8px !important;
                }

                td:first-child {
                    background-color: $ost-blackberry !important;
                    color: #ffffff !important;
                    overflow: hidden;
                }
            }
            tr:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .floorball-data {
        grid-template-columns: 1fr;
        .floorball .floorball {
            max-width: 200px;
            margin: 16px auto 32px;
        }
    }

    .component .data {
        flex-direction: column;
        .topic {
            width: 100%;
        }
    }

    .search-serialnumber {
        button {
            font-size: 0px;
        }
    }
}
