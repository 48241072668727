@import '../../ressources/styles/colors.scss';

.error-modal {
    .content {
        text-align: center;

        i {
            font-size: 80px;
            margin-bottom: 32px;
            color: $ost-raspberry;
        }

        h2 {
            margin-bottom: 16px;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0 !important;

        button {
            margin: 0 !important;
        }
    }
}
