@import '../../ressources/styles/colors.scss';

.order-overview {
    position: relative;
    border-radius: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  
    .order-amount {
      position: absolute;
      right: 15px;
      bottom: 15px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #d9d9d9;
      color: #191919;
      font-size: 14pt;
      border: 2px solid #8c195f;
    }

    .order-description {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100.5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        transition: opacity 0.4s ease;
        box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.9);

        h2 {
            color: #fff !important;
            font-size: 22px !important;
        }

        h3 {
            color: #999 !important;
            font-size: 18px !important;
        }

        i {
            font-size: 56px;
            margin-top: 16px;
            margin-right: 0;
        }

        i.check {
            color: green;
        }

        i.cogs {
            color: $ost-raspberry;
        }

        i.qrcode {
            color: green;
        }
    }
}

.order-overview:hover {
    cursor: pointer;
    .order-description {
        opacity: 1;
    }
}

.order-overview.inProduction::before {
    position: absolute;
    top: -2%;
    left: -2%;
    width: 104%;
    height: 104%;
    border-top: 12px solid $ost-raspberry;
    border-right: 12px solid $ost-raspberry;
    border-bottom: 12px solid black;
    border-left: 12px solid black;
    border-radius: 100%;
    content: '';
    animation: infinite-spinning 5s normal linear infinite;
}

.order-overview.inProduction,
.order-overview.readyForPickup,
.order-overview.inQueue {
    .order-description {
        opacity: 1;
    }
}

.order-overview.inQueue {
    i {
        color: $ball-blue;
    }
}

.order-overview.inProduction:hover,
.order-overview.readyForPickup:hover,
.order-overview.inQueue:hover {
    .order-description {
        opacity: 0.75 !important;
    }
}

.order-overview.readyForPickup::before {
    position: absolute;
    top: -2%;
    left: -2%;
    width: 104%;
    height: 104%;
    border: 8px solid green;
    border-radius: 100%;
    content: '';
}

.ui.modal.order-detail-modal {
    .description {
        .qrcode {
            width: 100%;
            text-align: center;

            i {
                font-size: 80px;
                margin-bottom: 24px;
                margin-right: 0px;
            }

            i.cogs {
                color: $ost-raspberry;
            }

            i.check {
                color: green;
            }

            i.clock {
                color: $ball-blue;
            }
        }
    }
}

@keyframes infinite-spinning {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Responsive */

@media screen and (max-width: 1080px) {
    .orders {
        .order-overview {
            flex-basis: calc(33% - 36px) !important;
        }
    }
}

@media screen and (max-width: 720px) {
    .orders {
        .order-overview {
            flex-basis: calc(50% - 36px) !important;
        }
        .order-description {
            opacity: 1;
        }
    }
}

@media screen and (max-width: 600px) {
    .orders {
        .order-overview {
            flex-basis: 300px !important;
        }
    }
}

@media screen and (max-width: 420px) {
    #orders {
        .order-overview {
            flex-basis: 90% !important;
        }
    }
}
