.floorball {
    position: relative;
    max-width: 400px;

    img {
        max-width: 100%;
    }

    .floorball-right {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .floorball-left {
        width: 100%;
    }
}
