#production-data {
    text-align: center;
    .search-serialnumber {
        display: flex;
        justify-content: center;
        .form {
            display: flex;
            .field {
                margin: 0;
                width: 100%;
                flex-grow: 2;
                margin-right: 16px;
            }
        }
    }

    .result {
        margin-top: 48px;
    }
}

@media screen and (max-width: 500px) {
    .search-serialnumber {
        .field {
            input {
                width: 100% !important;
                padding: 8px !important;
                font-size: 14px !important;
            }
        }

        .ui.button {
            font-size: 0px !important;
            width: 50px;
            position: relative;
            i {
                color: #ffffff;
                font-size: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 0%);
                margin: 0 !important;
            }
        }
    }
}
