@import '../../ressources/styles/colors.scss';

.admin-settings {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .orders-enabled {
        display: flex;
        align-items: center;
        column-gap: 16px;
    }

    .factory {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .factory-available {
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 8pt;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;

            &.available {
                background-color: #2E7D32;
            }
            &.unavailable {
                background-color: #C62828;
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .admin-settings {
        flex-direction: column;
        row-gap: 12px;
    }
}
