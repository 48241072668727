@import '../../ressources/styles/colors.scss';

#home {
    text-align: center;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

    h1 {
        font-size: 40px;
        margin-bottom: 10px;
        color: $ost-blackberry;
    }

    h3 {
        size: 18px;
        margin-bottom: 50px;
        color: $ost-gray;
    }

    #topics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .topic {
            text-align: center;
            max-width: 32%;
            padding: 36px 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: relative;
            border-radius: 6px;
            background-size: cover;
            background-position: center;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            z-index: 4;
            color: #fff;
            overflow: hidden;

            &.disabled {
                opacity: 0.2;
                pointer-events: none;
            }

            p {
                font-weight: 600;
                color: #999;
            }

            i {
                margin: 0 auto;
                font-size: 40px;
                margin-bottom: 16px;
            }

            i,
            h2,
            p {
                z-index: 10;
                transition: color 0.5s ease;
            }
            h2 {
                color: #fff;
            }
        }

        .production-data {
            background-image: url(../../ressources/styles/img/production_data.jpg);
        }

        .configurator {
            background-image: url(../../ressources/styles/img/configurator.jpg);
        }

        .orders {
            background-image: url(../../ressources/styles/img/ball_lager.jpg);
        }

        .topic:hover {
            color: #fff;

            h2 {
                color: $ost-blackberry;
            }

            p,
            i {
                color: #191919;
            }
            cursor: pointer;
        }

        .topic::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-color: rgba(0, 0, 0, 0.7);
            transition: background-color 0.5s ease;
            z-index: 5;
            backdrop-filter: blur(1px);
        }

        .topic:hover:after {
            background-color: rgba(0, 0, 0, 0.7);
            background-color: rgba(255, 255, 255, 1);
        }
    }
}

/* Responsive */

@media screen and (max-width: 800px) {
    #home {
      h3 {
        margin-bottom: 32px;
      }
      
        #topics {
            flex-direction: column-reverse;
            row-gap: 24px;

            .topic {
                max-width: 100%;
                padding: 16px 16px 8px 16px;
            }

            .configurator {
                order: 1;
            }
        }
    }
}
