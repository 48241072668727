@import '../../ressources/styles/colors.scss';

.floorball-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 24px;
    img {
        max-width: 60px;
    }
}

#colorpicker {
    margin: 32px 0;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #floorballwrapper {
        padding-bottom: 65px;
        background-size: 80%;
        background-position: bottom;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .floorball {
            margin: 0 auto;

            img {
                animation: infinite-spinning 70s normal linear infinite;
            }
        }
    }

    #firstcolor,
    #secondcolor {
        text-align: center;
        width: 29%;
        margin-top: 24px;
        h2 {
            margin-bottom: 40px;
        }

        .colorwrapper {
            margin: 4px;
        }
    }
}

@keyframes infinite-spinning {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Responsive */
#colorpicker.mobile {
    flex-direction: column;

    #mobileColors {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media screen and(max-width: 1600px) {
    #colorpicker {
        margin: 16px 0 0 0;
    }
    #floorballwrapper {
        margin-top: 32px;
        .floorball {
            max-width: 300px;
        }

        #canvas-container,
        #canvas-container .floorball-canvas {
            width: 300px !important;
            height: 300px !important;
        }
    }
}

@media screen and(max-width: 1280px) {
    #colorpicker {
        margin: 16px 0 0 0;
    }
}

@media screen and(max-width: 1190px) {
    #colorpicker {
        margin: 0px;
        .colorpalette {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 450px;
            justify-content: center;
            align-items: center;
            row-gap: 12px;
            column-gap: 0px;
            width: 200px;

            .colorwrapper {
                width: 75px;
                height: 75px;
                margin: 0 !important;
            }
        }

        #secondcolor .colorpalette {
            flex-direction: column;
            flex-wrap: wrap-reverse;
        }

        #firstcolor,
        #secondcolor {
            width: 22%;
            margin: 0;
        }
    }

    #floorballwrapper {
        margin-top: 32px;
        .floorball {
            max-width: 350px;
        }

        #canvas-container,
        #canvas-container .floorball-canvas {
            width: 350px !important;
            height: 350px !important;
        }
    }
}

@media screen and(max-width: 900px) {
    #colorpicker {
        margin: 32px 0;
        #floorballwrapper {
            margin-top: 0px;
            padding: 0 24px 64px;
            background-size: 350px !important;
        }
    }
}

@media screen and(max-width: 600px) {
    #colorpicker {
        #floorballwrapper {
            background-size: 70% !important;
            .floorball {
                max-width: 80%;
            }

            #canvas-container,
            #canvas-container .floorball-canvas {
                width: 90% !important;
                height: 90% !important;
            }
            .floorball-canvas {
                aspect-ratio: 1 / 1;
            }
        }
    }
}

@media screen and(max-width: 428px) {
    #colorpicker {
        margin: 20px 0;
        #floorballwrapper {
            padding-bottom: 48px;
        }
    }
    .colorwrapper {
        width: 85px;
        height: 85px;
    }
}

@media screen and(max-width: 414px) {
    #colorpicker {
        margin: 20px 0;
        #floorballwrapper {
            padding-bottom: 55px;
        }
    }
    .colorwrapper {
        width: 75px;
        height: 75px;
    }
    .ui.modal.colorModal {
        .content {
            padding: 0 16px !important;
            .description .colorpalette {
                row-gap: 16px;
                column-gap: 16px;
            }
        }
        .actions {
            padding: 0;
        }
    }
}

@media screen and(max-width: 375px) {
    #colorpicker {
        margin: 16px 0;
        #floorballwrapper {
            padding-bottom: 40px;
        }
    }
}

@media screen and(max-width: 375px) {
    #colorpicker {
        #floorballwrapper {
            padding-bottom: 32px;
        }
    }
}
