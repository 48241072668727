@import '../../ressources/styles/colors.scss';

#orderswrapper {
    flex: 1 1 auto;
    margin-bottom: 24px;
    .orders {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 64px;
        row-gap: 48px;
        column-gap: 48px;

        .order-overview {
            flex: 0 0 calc(25% - 36px);
            aspect-ratio: 1 / 1;
        }
    }

    .orders-loader {
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .filter {
        display: flex;
        justify-content: flex-end;

        i {
            font-size: 32px;
            margin-left: 8px;
            margin-top: 4px;
            color: $ost-blackberry;
            cursor: pointer;
            transition: color 0.4s ease;
        }

        i:hover {
            color: $ost-black;
        }

        .filters {
            width: 0px;
            overflow: hidden;
            transition: width 1s ease, height 1s ease;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-end;
            input {
                font-size: 14px;
                font-weight: 500;
                padding: 8px 16px;
            }
        }

        .filters.visible {
            width: 90%;
        }

        .filter-buttons {
            display: flex;
            button {
                margin: 0 8px;
                padding: 8px 16px;
                background-color: transparent;
                border: 2px solid $ost-blackberry;
                color: $ost-black;
                font-size: 14px;
                border-radius: 25px;
                white-space: nowrap;
            }

            i {
                margin: 0 !important;
                font-size: 18px;
            }

            .closed > i,
            .ready > i {
                color: green;
            }

            .production > i {
                color: $ost-raspberry;
            }

            button:hover {
                border: 2px solid $ost-raspberry;
            }

            button.active {
                background-color: $ost-blackberry;
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }

        .color-filter {
            margin: 0 8px;
            border: 2px solid $ost-blackberry;
            border-radius: 50%;
            .colorwrapper {
                width: 40px;
                height: 40px;
                box-shadow: none;
                background-color: transparent;
                transition: opacity 0.2s ease;
                position: relative;
                padding: 0;
                margin: 0;
                display: inherit;
                .floorball-color {
                    background-size: 100%;
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    border: none;
                    box-shadow: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .colorwrapper:hover {
                opacity: 0.6;
                background-image: none;
                border: none;
            }
        }
    }

    .show-more {
        display: flex;
        justify-content: center;
        padding: 48px 0 0;
    }
}

@media screen and (max-width: 900px) {
    #orderswrapper .filter {
        justify-content: center;
    }
}

@media screen and (max-width: 700px) {
    .filter {
        display: flex;
        flex-direction: column-reverse;
        width: 300px;
        margin: 0 auto;
        text-align: center;
        align-items: center;
        row-gap: 8px;

        .filters {
            flex-direction: column-reverse;
            width: 100% !important;
            height: 0px;
            row-gap: 8px;
            .state-filter {
                flex-direction: column;
                row-gap: 8px;
            }
            .filter-buttons {
                justify-content: space-around;
            }
        }

        .filters.visible {
            height: 100px;
        }
    }
    #orderswrapper .orders {
        margin-top: 24px;
    }
}

@media screen and (max-width: 600px) {
    #orderswrapper {
        .filter {
            justify-content: center;
        }
    }
}
