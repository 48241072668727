@import '../../ressources/styles/colors.scss';

#footerwrapper {
    width: 100%;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px #00000066;
    z-index: 1;

    #footer {
        max-width: 1280px;
        margin: 0 auto;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 550px) {
    #footerwrapper {
        #footer {
            flex-direction: column;
            row-gap: 12px;
            padding: 12px;
        }
    }
}
