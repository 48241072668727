@import '../../ressources/styles/colors.scss';

#headerwrapper {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 15px 0px #00000066;
    z-index: 11;
}

#header {
    max-width: 1280px;
    margin: 8px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    #logo img {
        height: 50px;
    }

    #auth {
        button {
            margin-left: 8px;
            font-size: 14px;
            padding: 8px 16px;
        }
    }

    #user {
        font-size: 14pt;
        display: flex;
        align-items: center;

        i {
            font-size: 18pt;
            margin-left: 8px;
            margin-right: 0;
            display: inline-block;
            color: $ost-blackberry;
            cursor: pointer;
            transition: color 0.4s ease;
        }

        i:hover {
            color: $ost-raspberry;
        }
    }
}

.translations {
    ul li {
        display: inline-block;
        margin: 0;
    }
    button {
        color: #191919;
        font-size: 13px;
        font-weight: 400;
        padding: 0;
        background: none;
        border: none;
        &::after{
            content: "|";
            font-size: .875rem;
            margin: 0 0.1875rem;
            color: #191919;
        }
        &:hover {
            border: none;
            cursor: pointer;
            color: #8c195f;
        }
    }
    li:last-of-type button::after{
        content: none;
    }
}

.left-side {
    display: flex;
    align-items: center;
    div {
        margin-right: 30px;
    }
}

/* Responsive */
@media screen and (max-width: 800px) {
  #logo {
    width: 70px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 420px) {
    #header {
        flex-direction: row;

        #logo {
            img {
                max-height: 50px;
            }
        }

        #user {
            text-align: center;
            padding: 8px 0;
            display: flex;
            justify-content: flex-end;
            margin-left: 8px;
            font-size: 16px;

            i {
                font-size: 24px;
            }
        }

        #auth button {
            font-size: 12px;
            padding: 8px 12px 6px;
        }
    }
}
