@import '../../../ressources/styles/colors.scss';

.colorwrapper.modal {
    .floorball-color {
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.ui.modal.colorModal {
    position: absolute !important;
    top: 50% !important;
    transform: translate(0%, -50%) !important;
    max-width: 450px;

    .header {
        text-align: center;
    }

    .description {
        display: flex;
        justify-content: center;
    }

    .description .colorpalette {
        display: flex !important;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        column-gap: 24px;
        row-gap: 24px;
        max-width: 400px;
    }

    .actions {
        text-align: center;

        button {
            border: none;
        }
    }
}

@media screen and (max-width: 675px) {
    .ui.modal.colorModal {
        .description {
            .colorpalette {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr;
                .colorwrapper {
                    margin: 0 auto;
                }
            }
        }
    }
}
