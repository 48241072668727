@import './colors.scss';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Actual styles */

body {
    line-height: 1;
    margin: 0;
    font-family: 'Roboto', monospace;
    font-style: normal;
    font-weight: 400;
}

#bg {
    background: url('./img/config_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
}

#root {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    z-index: -2;
}

#content {
    z-index: 2;
    width: 100%;
    max-width: 1280px;
    margin: 28px auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex-grow: 1;

    h1 {
        font-size: 36px;
        color: $ost-black;
        margin-bottom: 16px;
        text-align: center;
    }

    h2 {
        font-size: 28px;
        color: $ost-blackberry;
        margin-bottom: 12px;
    }

    h3 {
        font-size: 22px;
        color: $ost-raspberry;
        margin-bottom: 8px;
    }

    h4 {
        font-size: 18px;
        margin-bottom: 4px;
    }

    p {
        margin-bottom: 16px;
    }
}

h1,
h2,
h3,
h4,
.ui.button {
    font-family: 'Roboto', monospace;
    font-weight: bold;
}

p,
ul,
ol,
a,
.ui.form input {
    font-family: 'Roboto', monospace;
    font-weight: 500 !important;
    line-height: 1.1;
}

a {
    color: $ost-blackberry;
    transition: color 0.4s ease;
}

a:hover {
    color: $ost-raspberry;
}

.red {
    color: $ost-raspberry;
}

button,
.button,
.ui.button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: $ost-blackberry;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: background-color 0.2s ease, color 0.2s ease, border 0.2s ease;

    i {
        margin: 0 8px 0 -4px !important;
    }
}

button.inverted,
.button.inverted,
.ui.button.inverted {
    background-color: transparent;
    color: $ost-blackberry;
    border: 2px solid $ost-blackberry;
}

button:hover,
.button:hover,
.ui.button:hover {
    background-color: $ost-raspberry;
    border-color: $ost-raspberry;
    cursor: pointer;
}

button.inverted:hover,
.button.inverted:hover .ui.button.inverted:hover {
    color: #fff;
}

button.disabled,
button:disabled,
.ui.button:disabled {
    border: 2px solid #fff;
    background-color: $ost-gray;
    color: #777;
    cursor: default;
}

.ui.modal button:hover {
    border: 2px solid transparent;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    font-family: 'Roboto', monospace !important;
    border: 2px solid transparent !important;
    transition: border 0.4s ease !important;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
textarea:focus {
    border: 2px solid $ost-blackberry !important;
}

// Theme

.theme ~ #bg {
    background-image: url('./img/campus_rapperswil.jpg') !important;
}

.theme {
    header {
        z-index: 11;
        #logo::after {
            content: '';
            width: 0px;
            height: 50px;
            position: absolute;
            top: 50px;
            left: 20%;
            background: url(./img/heart.png);
            background-size: 100%;
            background-repeat: no-repeat;
            animation: falling 5s ease-in 1;
            overflow: hidden;
            opacity: 0;
        }
    }
    #content {
        z-index: 10;
        color: #fff !important;
        h1 {
            color: #fff;
        }
    }

    button,
    .button,
    .ui.button,
    #order-navigator button {
        background-color: $ball-blue;
    }

    button:hover,
    .button:hover,
    .ui.button:hover {
        background-color: #fff;
        color: $ball-blue;
        border: 2px solid $ball-blue;
    }

    button.inverted,
    .button.inverted,
    .ui.button.inverted {
        background-color: #fff;
        color: $ball-blue;
        border: 2px solid $ball-blue;
    }

    button.inverted:hover,
    .button.inverted:hover .ui.button.inverted:hover {
        background-color: $ball-blue;
        color: #fff;
    }

    .floorball-toggle {
        img {
            filter: invert(1);
        }
    }

    .colorpalette .colorwrapper.active {
        border-color: $ball-blue;
    }

    .ui.toggle.checkbox label {
        color: #fff !important;
    }
}

.theme::before {
    z-index: 1;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Animation */

@keyframes falling {
    0% {
        top: 50px;
        opacity: 0;
        width: 50px;
    }
    1% {
        opacity: 1;
    }
    100% {
        top: calc(100% - 50px);
        width: 50px;
    }
}

/* Responsive */

@media screen and(max-width: 1600px) {
    #content {
        margin: 22px auto;
        .back-button {
            margin: 0;
        }
    }
}

@media screen and(max-width: 1024px) {
    #content {
        margin: 32px 0;
        .back-button {
            display: none;
        }
    }
}

@media screen and(max-width: 500px) {
    #content {
        h1 {
            font-size: 28px;
            margin-bottom: 14px;
            margin-top: 16px;
        }

        h2 {
            font-size: 24px;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 18px;
            margin-bottom: 6px;
        }

        h4 {
            font-size: 16px;
            margin-bottom: 4px;
        }

        p {
            margin-bottom: 12px;
        }
    }
}

@media screen and(max-width: 390px) {
    #content {
        margin: 16px 0;
    }
    #footerwrapper #footer {
        padding: 12px 24px !important;
    }
}
