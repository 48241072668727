@import '../../../ressources/styles/colors';

.colorwrapper {
    display: inline-block;
    position: relative;
    text-decoration: none;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    background-color: #f7f7f7;
    border: none;
    transition: background-image 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.colorwrapper:hover {
    background-image: -webkit-linear-gradient(#efefef 0%, #d6d6d6 100%);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 2px rgba(0, 0, 0, 0.19);
}

.floorball-color {
    position: absolute;
    content: '';
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 3px outset #ffffff20;
    border-right-color: #00000020;
    border-bottom-color: #00000020;
}

.floorball-color:hover {
cursor: pointer;
}

.colorwrapper.disabled {
opacity: 0.2;
.floorball-color:hover {
    cursor: default;
}
}

.colorwrapper.disabled:hover {
    background-image: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
