.account-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  p {
      margin-top: 16px;
  }
}

.ui.message p {
  margin: 0!important;
}