@import '../../ressources/styles/colors.scss';

#order-confirmation {
    h1 {
        margin-bottom: 36px;
        font-size: 22pt;
    }

    h3 {
        color: $ost-black;
        font-size: 15pt;
    }

    p {
        color: #454545;
        font-weight: 600;
    }

    .order-summary {
        display: flex;
        flex-direction: row;
        column-gap: 36px;
        justify-content: center;
        align-items: center;

        .confirmation-floorball {
            max-width: 350px;
        }

        .order-description {
            max-width: 350px;
        }
    }
}

@media screen and (max-width: 600px) {
    #order-confirmation {
        .order-summary {
            flex-direction: column;
            row-gap: 36px;

            .confirmation-floorball {
                max-width: 200px;
            }

            .order-description {
                max-width: 100%;
            }
        }
    }
}
